// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/icons/vector.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "#breadcrumb-container[data-v-63cc8cb2]{margin:0 0 10px}@media screen and (min-width:1100px){#breadcrumb-container[data-v-63cc8cb2]{margin-bottom:0;margin-top:51px}#breadcrumb-container[data-v-63cc8cb2]:not(.no-margin){margin-left:80px;margin-right:80px}}#breadcrumb-container.custom-flow[data-v-63cc8cb2]{margin:0}.breadcrumb-list[data-v-63cc8cb2]{background:#f5f5f5;display:flex;flex-wrap:wrap;line-height:1;margin:0 auto;padding:10px 20px}@media screen and (min-width:1100px){.breadcrumb-list[data-v-63cc8cb2]{background:transparent none repeat 0 0/auto auto padding-box border-box scroll;background:initial;max-width:1100px;padding:0}}li[data-v-63cc8cb2]{font-family:\"Fira Sans\",\"Fira Sans fallback\",\"Fira Sans Fallback Android\",verdana,helvetica,arial,sans-serif;font-size:11px;font-weight:300;line-height:1}@media screen and (min-width:1100px){li[data-v-63cc8cb2]{font-size:12px}}li[data-v-63cc8cb2]:not(:last-child):after{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat 50%;content:\"\";display:inline-block;height:8px;margin:0 10px;width:4px}@media screen and (min-width:1100px){li[data-v-63cc8cb2]:not(:last-child):after{margin:0 8px}}a[data-v-63cc8cb2]{align-content:center;color:#000;display:inline-block;height:29px;line-height:1;-webkit-text-decoration:none;text-decoration:none;vertical-align:baseline}a img[data-v-63cc8cb2]{bottom:1px;position:relative}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
