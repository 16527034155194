var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"temperature-card"},[_c('h2',{staticClass:"temperature-card__title"},[_vm._v(_vm._s(_vm.title))]),_vm._v(" "),_c('div',{staticClass:"temperature-card__container"},_vm._l((_vm.temperatures),function(stat,index){return _c('div',{key:'temp-' + index,staticClass:"temperature-card__column"},[_c('div',{class:[
          'temperature-card__label',
          index === 0
            ? 'temperature-card__label--left'
            : 'temperature-card__label--right',
        ],style:({ bottom: _vm.calculateHeight(stat.temperature + 1) + '%' })},[_vm._v("\n        "+_vm._s(_vm.formatNumberToString(stat.temperature))+"°C\n      ")]),_vm._v(" "),_c('div',{class:[
          'temperature-card__line',
          index === 0
            ? 'temperature-card__line--left'
            : 'temperature-card__line--right',
        ],style:({ bottom: _vm.calculateHeight(stat.temperature + 1) + '%' })}),_vm._v(" "),_c('div',{staticClass:"temperature-card__bar-container"},[_c('div',{staticClass:"temperature-card__bar"},[_c('div',{class:[
              'temperature-card__fill',
              index > 0 ? 'temperature-card__fill--gray' : '',
            ],style:({ height: _vm.calculateHeight(stat.temperature) + '%' })})]),_vm._v(" "),_c('div',{class:[
            'temperature-card__bulb',
            index > 0 ? 'temperature-card__bulb--gray' : '',
          ]})])])}),0),_vm._v(" "),_c('div',{staticClass:"temperature-card__legend"},[_c('div',{staticClass:"temperature-card__legend-item"},[_vm._v("\n      Durchschnitt "+_vm._s(_vm.getPreviousYear())+"\n    ")]),_vm._v(" "),_c('div',{staticClass:"temperature-card__legend-item"},[_vm._v("Letzte 20 Jahre")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }