var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:`oil-import-table-organism is--variant-${_vm.variant}`},[_c('div',{class:{
      'oil-import-table-container': true,
      'oil-import-table-container--heating-oil': !_vm.isPelletsSite,
    }},[_c('table',{class:{
        'oil-import-table': true,
        [_vm.classAdd]: true,
        'oil-import-table--heating-oil': !_vm.isPelletsSite,
      }},[_c('thead',{class:`is--indexed-${_vm.indexed}`},[_c('tr',_vm._l((_vm.tableHeaderData),function(item,i){return _c('TableHeader',{key:item[i],attrs:{"content":item,"isPelletsSite":_vm.isPelletsSite}})}),1)]),_vm._v(" "),(_vm.indexed)?_c('tbody',{class:`is--indexed-${_vm.indexed}`},_vm._l((_vm.tableRowData),function(item,i){return _c('OilImportTableRow',{key:item[i],attrs:{"data":item,"index":i + 1 + '.',"colored":_vm.dividableByTwo(i),"showIcon":_vm.showIcon}})}),1):_c('tbody',{class:`is--indexed-${_vm.indexed}`},_vm._l((_vm.tableRowData),function(item,i){return _c('OilImportTableRow',{key:`${_vm.tableRowData[i][0].text}-${i}`,attrs:{"data":item,"colored":_vm.dividableByTwo(i),"hideMobileColumns":_vm.hideMobileColumns,"showIcon":_vm.showIcon}})}),1)])]),_vm._v(" "),(_vm.figcaption)?_c('EsyFigcaption',[_vm._v("\n    "+_vm._s(_vm.figcaption)+"\n  ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }