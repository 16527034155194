
import { Component, Prop, Vue } from "nuxt-property-decorator";
import { BreadCrumbDetail } from "~/types/BreadCrumb";

@Component
export default class BreadCrumb extends Vue {
  @Prop() breadCrumbData: BreadCrumbDetail[];
  @Prop({ default: "default" }) margin: "default" | "none";
  @Prop({ default: false }) customFlow: boolean;
  @Prop({ default: "" }) suffix: string;
  @Prop({ default: "Heizölpreise" }) prefix: string;
  // Dirty hack to suppress pre-/suffixes to use this component in other contexts. Should really be refactored in the future.
  @Prop({ default: false }) suppressNameAdjustments: boolean;

  shouldShowLandkreis(crumb: BreadCrumbDetail, index: number): boolean {
    return index === 2 && !crumb.name.includes("Kreisfreie Stadt");
  }
}
