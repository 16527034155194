
import { Component, Prop, Vue } from "nuxt-property-decorator";
import HeatingDaysCard from "~/components/molecules/display/HeatingDaysCard.vue";
import TemperatureCard from "~/components/molecules/display/TemperatureCard.vue";
import HeatingOilConsumption from "~/components/organisms/display/HeatingOilConsumption.vue";

import {
  HeatingDay,
  OilRequirements,
  WeatherData,
} from "~/types/OilRequirement";

import {
  calculateAbsoluteDifference,
  calculatePercentageDifference,
  currentMonth,
  getPreviousYear,
  formatHeatingDays,
  formatNumberToString,
  formatPercentageWithoutZeros,
  getDirection,
} from "~/utils/oilRequirementUtils";

@Component({
  components: {
    TemperatureCard,
    HeatingDaysCard,
    HeatingOilConsumption,
  },
})
export default class HeatingStatistics extends Vue {
  @Prop() city: string;
  @Prop() zipcode: string;
  @Prop() oilRequirements: OilRequirements;

  maxHeatingDays = 365;
  minTemp = -5;
  maxTemp = 35;

  formatPercentageWithoutZeros = formatPercentageWithoutZeros;
  formatNumberToString = formatNumberToString;
  formatHeatingDays = formatHeatingDays;
  currentMonth = currentMonth;

  strongStyle = 'style="font-weight: bold;"';

  get cityWeatherData(): WeatherData {
    return this.oilRequirements.weatherData;
  }

  get temperatureDifference2023() {
    const avg2023 = this.cityWeatherData.outdoorTemp_Avg2023;
    const avgLongTerm = this.cityWeatherData.outdoorTemp_AvgLongTermAvg;
    const diff = avg2023 - avgLongTerm;
    return {
      percentage: parseFloat(
        calculatePercentageDifference(avg2023, avgLongTerm),
      ),
      direction: getDirection(diff, "über", "unter"),
    };
  }

  get temperatureDifferenceCurrentMonth() {
    const mayTemp = this.cityWeatherData.currentMonthData.outdoorTemp;
    const mayLongTermAvg =
      this.cityWeatherData.currentMonthData.outdoorTemp_LongTermAvg;
    const diff = mayTemp - mayLongTermAvg;
    return {
      percentage: parseFloat(
        calculatePercentageDifference(mayTemp, mayLongTermAvg),
      ),
      direction: getDirection(diff, "höher", "niedriger"),
    };
  }

  get heatingDaysDifference() {
    const days2023 = this.cityWeatherData.heatingDays_Total2023;
    const daysLongTermAvg = this.cityWeatherData.heatingDays_TotalLongTermAvg;
    return parseFloat(calculatePercentageDifference(days2023, daysLongTermAvg));
  }

  get heatingTempDifference() {
    const temp2023 = this.cityWeatherData.outdoorTempHeatingDays_Avg2023;
    const tempLongTermAvg =
      this.cityWeatherData.outdoorTempHeatingDays_AvgLongTermAvg;
    return parseFloat(calculatePercentageDifference(temp2023, tempLongTermAvg));
  }

  get heatingDaysDifferenceMay() {
    const mayDays = this.cityWeatherData.currentMonthData.heatingDays;
    const mayLongTermAvg =
      this.cityWeatherData.currentMonthData.heatingDays_LongTermAvg;
    const diff = calculateAbsoluteDifference(mayDays, mayLongTermAvg);
    return {
      difference: diff,
      percentage: parseFloat(
        calculatePercentageDifference(mayDays, mayLongTermAvg),
      ),
    };
  }

  get heatingTempDifferenceMay() {
    const mayTemp =
      this.cityWeatherData.currentMonthData.outdoorTempHeatingDays;
    const mayLongTermAvg =
      this.cityWeatherData.currentMonthData.outdoorTempHeatingDays_LongTermAvg;
    return parseFloat(calculatePercentageDifference(mayTemp, mayLongTermAvg));
  }

  get heatingDays(): HeatingDay[] {
    return [
      {
        label: `Durchschnitt ${getPreviousYear()}`,
        days: formatHeatingDays(this.cityWeatherData.heatingDays_Total2023),
      },
      {
        label: "Letzte 20 Jahre",
        days: formatHeatingDays(
          this.cityWeatherData.heatingDays_TotalLongTermAvg,
        ),
      },
    ];
  }

  get outdoorTemperature(): HeatingDay[] {
    return [
      {
        label: `Durchschnitt ${getPreviousYear()}`,
        temperature: this.cityWeatherData.outdoorTemp_Avg2023,
      },
      {
        label: "Letze 20 Jahre",
        temperature: this.cityWeatherData.outdoorTemp_AvgLongTermAvg,
      },
    ];
  }

  get heatDaysTemperature(): HeatingDay[] {
    return [
      {
        label: `Durchschnitt ${getPreviousYear()}`,
        temperature: this.cityWeatherData.outdoorTempHeatingDays_Avg2023,
      },
      {
        label: "Letze 20 Jahre",
        temperature: this.cityWeatherData.outdoorTempHeatingDays_AvgLongTermAvg,
      },
    ];
  }

  get temperatureComparison2023Text(): string {
    const temp2023 = this.cityWeatherData.outdoorTemp_Avg2023;
    const tempLongTerm = this.cityWeatherData.outdoorTemp_AvgLongTermAvg;
    const formattedTemp2023 = this.formatNumberToString(temp2023);
    const formattedTempLongTerm = this.formatNumberToString(tempLongTerm);

    if (Math.abs(temp2023 - tempLongTerm) < 0.1) {
      return `In ${this.zipcode} ${
        this.city
      } betrug die Außentemperatur im Jahr ${getPreviousYear()} durchschnittlich <strong ${
        this.strongStyle
      }>(temperature: ${formattedTemp2023}°C)</strong>. Das entspricht der durchschnittlichen Außentemperatur der letzten 20 Jahre.`;
    } else {
      return `In ${this.zipcode} ${
        this.city
      } betrug die Außentemperatur im Jahr ${getPreviousYear()} durchschnittlich <strong ${
        this.strongStyle
      }>${formattedTemp2023}°C</strong>. Damit lag die Temperatur um <strong ${
        this.strongStyle
      }>${this.formatPercentageWithoutZeros(
        this.temperatureDifference2023.percentage,
        false,
        false,
      )} ${
        this.temperatureDifference2023.direction
      }</strong> der durchschnittlichen Außentemperatur der letzten 20 Jahre von <strong ${
        this.strongStyle
      }>${formattedTempLongTerm}°C</strong>.`;
    }
  }

  get temperatureComparisonCurrentMonthText(): string {
    const tempCurrentMonth = this.cityWeatherData.currentMonthData.outdoorTemp;
    const tempLongTerm =
      this.cityWeatherData.currentMonthData.outdoorTemp_LongTermAvg;
    const formattedTempCurrentMonth =
      this.formatNumberToString(tempCurrentMonth);
    const formattedTempLongTerm = this.formatNumberToString(tempLongTerm);

    if (Math.abs(tempCurrentMonth - tempLongTerm) < 0.1) {
      return `Die durchschnittliche Außentemperatur in ${this.zipcode} ${
        this.city
      } im ${currentMonth()} ${getPreviousYear()} betrug <strong ${
        this.strongStyle
      }>${formattedTempCurrentMonth}°C</strong>. Das entspricht der durchschnittlichen Temperatur <strong ${
        this.strongStyle
      }>im Monat ${currentMonth()}</strong> in den letzten 20 Jahren.`;
    } else {
      return `Die durchschnittliche Außentemperatur in ${this.zipcode} ${
        this.city
      } im ${currentMonth()} ${getPreviousYear()} betrug <strong ${
        this.strongStyle
      }>${formattedTempCurrentMonth}°C</strong>. Das ist <strong ${
        this.strongStyle
      }>${this.formatPercentageWithoutZeros(
        this.temperatureDifferenceCurrentMonth.percentage,
        false,
        false,
      )} ${
        this.temperatureDifferenceCurrentMonth.direction
      }</strong> als die durchschnittliche Temperatur von <strong ${
        this.strongStyle
      }>${formattedTempLongTerm}°C im Monat ${currentMonth()}</strong> in den letzten 20 Jahren.`;
    }
  }

  get heatingDaysComparisonText(): string {
    const days2023 = this.cityWeatherData.heatingDays_Total2023;
    const daysLongTerm = this.cityWeatherData.heatingDays_TotalLongTermAvg;
    const temp2023 = this.cityWeatherData.outdoorTempHeatingDays_Avg2023;
    const tempLongTerm =
      this.cityWeatherData.outdoorTempHeatingDays_AvgLongTermAvg;

    const formattedDays2023 = this.formatHeatingDays(days2023);
    const formattedDaysLongTerm = this.formatHeatingDays(daysLongTerm);
    const formattedTemp2023 = this.formatNumberToString(temp2023);
    const formattedTempLongTerm = this.formatNumberToString(tempLongTerm);

    const daysDiff = Math.abs(days2023 - daysLongTerm) < 0.5;
    const tempDiff = Math.abs(temp2023 - tempLongTerm) < 0.1;

    if (daysDiff && tempDiff) {
      return `In ${getPreviousYear()} betrug die Anzahl Heiztage in ${
        this.zipcode
      } ${this.city} im Mittel <strong ${
        this.strongStyle
      }>${formattedDays2023} Tage</strong> pro Jahr, die durchschnittliche Außentemperatur an Heiztagen betrug <strong ${
        this.strongStyle
      }>${formattedTemp2023}°C</strong>. Das entspricht dem Durchschnitt der letzten 20 Jahre.`;
    } else if (daysDiff) {
      return `In ${getPreviousYear()} wurde in ${this.zipcode} ${
        this.city
      } durchschnittlich <strong ${
        this.strongStyle
      }>an ${formattedDays2023} Tagen</strong> geheizt. Das entspricht dem Durchschnitt der letzten 20 Jahre. Die durchschnittliche Außentemperatur der letzten 20 Jahre an Heiztagen betrug <strong ${
        this.strongStyle
      }>${formattedTempLongTerm}°C</strong>. In ${getPreviousYear()} betrug die durchschnittliche Außentemperatur an diesen Tagen <strong ${
        this.strongStyle
      }>${formattedTemp2023}°C ${this.formatPercentageWithoutZeros(
        this.heatingTempDifference,
      )}</strong>.`;
    } else if (tempDiff) {
      return `In den letzten 20 Jahren betrug die Anzahl Heiztage in ${
        this.zipcode
      } ${this.city} im Mittel <strong ${
        this.strongStyle
      }>${formattedDaysLongTerm} Tage</strong> pro Jahr, die durchschnittliche Außentemperatur an Heiztagen betrug <strong ${
        this.strongStyle
      }>${formattedTempLongTerm}°C</strong>. In ${getPreviousYear()} wurde in ${
        this.zipcode
      } ${this.city} durchschnittlich <strong ${
        this.strongStyle
      }>an ${formattedDays2023} Tagen${this.formatPercentageWithoutZeros(
        this.heatingDaysDifference,
      )}</strong> geheizt. Die durchschnittliche Außentemperatur entsprach ${getPreviousYear()} dem Durchschnitt der letzten 20 Jahre.`;
    } else {
      return `In den letzten 20 Jahren betrug die Anzahl Heiztage in ${
        this.zipcode
      } ${this.city} im Mittel <strong ${
        this.strongStyle
      }>${formattedDaysLongTerm} Tage</strong> pro Jahr, die durchschnittliche Außentemperatur an Heiztagen betrug <strong ${
        this.strongStyle
      }>${formattedTempLongTerm}°C</strong>. In ${getPreviousYear()} wurde in ${
        this.zipcode
      } ${this.city} durchschnittlich <strong ${
        this.strongStyle
      }>an ${formattedDays2023} Tagen${this.formatPercentageWithoutZeros(
        this.heatingDaysDifference,
      )}</strong> geheizt. An diesen Tagen betrug die durchschnittliche Außentemperatur <strong ${
        this.strongStyle
      }>${formattedTemp2023}°C ${this.formatPercentageWithoutZeros(
        this.heatingTempDifference,
      )}</strong>.`;
    }
  }

  get heatingDaysComparisonCurrentMonthText(): string {
    const daysCurrentMonth = this.cityWeatherData.currentMonthData.heatingDays;
    const daysLongTerm =
      this.cityWeatherData.currentMonthData.heatingDays_LongTermAvg;
    const tempCurrentMonth =
      this.cityWeatherData.currentMonthData.outdoorTempHeatingDays;
    const tempLongTerm =
      this.cityWeatherData.currentMonthData.outdoorTempHeatingDays_LongTermAvg;

    const formattedDaysCurrentMonth = this.formatHeatingDays(daysCurrentMonth);
    const formattedDaysLongTerm = this.formatHeatingDays(daysLongTerm);
    const formattedTempCurrentMonth =
      this.formatNumberToString(tempCurrentMonth);
    const formattedTempLongTerm = this.formatNumberToString(tempLongTerm);

    const daysDiff = Math.abs(daysCurrentMonth - daysLongTerm) < 0.5;
    const tempDiff = Math.abs(tempCurrentMonth - tempLongTerm) < 0.1;

    if (daysDiff && tempDiff) {
      return `Im Monat ${currentMonth()} ${getPreviousYear()} wurde in ${
        this.zipcode
      } ${this.city} durchschnittlich <strong ${
        this.strongStyle
      }>an ${formattedDaysCurrentMonth} Tagen</strong> geheizt. Die durchschnittliche Außentemperatur an Heiztagen im Monat ${currentMonth()} betrug ${getPreviousYear()} <strong ${
        this.strongStyle
      }>${formattedTempCurrentMonth}°C</strong>. Diese Werte entsprechen dem Durchschnitt der letzten 20 Jahre.`;
    } else if (daysDiff) {
      return `Im Monat ${currentMonth()} ${getPreviousYear()} wurde in ${
        this.zipcode
      } ${
        this.city
      } durchschnittlich an ${formattedDaysCurrentMonth} Tagen geheizt. Das entspricht dem Durchschnitt der letzten 20 Jahre. Die durchschnittliche Außentemperatur an Heiztagen im Monat ${currentMonth()} betrug im Mittel der letzten 20 Jahre <strong ${
        this.strongStyle
      }>${formattedTempLongTerm}°C</strong>. Im Jahr ${getPreviousYear()} betrug die durchschnittliche Außentemperatur an beheizten Tagen im Monat ${currentMonth()} <strong ${
        this.strongStyle
      }>${formattedTempCurrentMonth}°C ${this.formatPercentageWithoutZeros(
        this.heatingTempDifferenceMay,
      )}</strong>.`;
    } else if (tempDiff) {
      return `Im Monat ${currentMonth()} ${getPreviousYear()} wurde in ${
        this.zipcode
      } ${this.city} durchschnittlich an <strong ${
        this.strongStyle
      }>${formattedDaysCurrentMonth} Tagen</strong> geheizt. Das sind <strong ${
        this.strongStyle
      }>${this.formatHeatingDays(
        Math.abs(this.heatingDaysDifferenceMay.difference),
      )} Tage ${
        this.heatingDaysDifferenceMay.difference > 0 ? "mehr" : "weniger"
      } (${this.formatPercentageWithoutZeros(
        this.heatingDaysDifferenceMay.percentage,
        false,
      )})</strong> als im Durchschnitt der letzten 20 Jahre <strong ${
        this.strongStyle
      }>(${formattedDaysLongTerm} Heiztage)</strong>. Die durchschnittliche Außentemperatur an Heiztagen im Monat ${currentMonth()} betrug ${getPreviousYear()} <strong ${
        this.strongStyle
      }>${formattedTempCurrentMonth}°C</strong>. Das entspricht der durchschnittlichen Außentemperatur der letzten 20 Jahre.`;
    } else {
      return `Im Monat ${currentMonth()} ${getPreviousYear()} wurde in ${
        this.zipcode
      } ${this.city} durchschnittlich <strong ${
        this.strongStyle
      }>an ${formattedDaysCurrentMonth} Tagen geheizt</strong>. Das sind <strong ${
        this.strongStyle
      }>${this.formatHeatingDays(
        Math.abs(this.heatingDaysDifferenceMay.difference),
      )} Tage ${
        this.heatingDaysDifferenceMay.difference > 0 ? "mehr" : "weniger"
      } (${this.formatPercentageWithoutZeros(
        this.heatingDaysDifferenceMay.percentage,
        false,
      )})</strong> als im Durchschnitt der letzten 20 Jahre <strong ${
        this.strongStyle
      }>(${formattedDaysLongTerm} Heiztage)</strong>. Die durchschnittliche Außentemperatur an Heiztagen im Monat ${currentMonth()} betrug im Mittel der letzten 20 Jahre <strong ${
        this.strongStyle
      }>${formattedTempLongTerm}°C</strong>. Im Jahr ${getPreviousYear()} betrug die durchschnittliche Außentemperatur an beheizten Tagen im Monat ${currentMonth()} <strong ${
        this.strongStyle
      }>${formattedTempCurrentMonth}°C ${this.formatPercentageWithoutZeros(
        this.heatingTempDifferenceMay,
      )}</strong>.`;
    }
  }
}
