var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:[
    {
      'no-margin': _vm.margin === 'none',
      'custom-flow': _vm.customFlow,
    },
  ],attrs:{"id":"breadcrumb-container"}},[_c('ol',{class:['breadcrumb-list']},[_c('li',[_c('NuxtLink',{staticClass:"breadcrumb-home",attrs:{"to":"/"}},[_c('img',{attrs:{"src":require('~/assets/icons/home.svg'),"alt":"Breadcrumb-Home-Bild","loading":"lazy"}})])],1),_vm._v(" "),_vm._l((_vm.breadCrumbData),function(crumb,index){return _c('li',{key:index},[(!crumb.lastItem)?_c('NuxtLink',{attrs:{"to":crumb.linkUrl}},[_vm._v("\n        "+_vm._s(!_vm.suppressNameAdjustments ? _vm.prefix : "")+"\n        "+_vm._s(!_vm.suppressNameAdjustments && _vm.shouldShowLandkreis(crumb, index)
            ? "Landkreis "
            : "")+"\n        "+_vm._s(crumb.name)+"\n      ")]):_c('NuxtLink',{attrs:{"to":crumb.linkUrl}},[_vm._v("\n        "+_vm._s(!_vm.suppressNameAdjustments ? `${_vm.prefix} ${_vm.suffix}` : "")+"\n        "+_vm._s(crumb.name)+" "+_vm._s(crumb?.zipcode))])],1)})],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }